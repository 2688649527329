




















import { Vue, Component, Prop } from "vue-property-decorator";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";

@Component({
  components: {
    SectionTitle,
  },
})
export default class ItemList<T> extends Vue {
  @Prop({ default: () => [] }) items!: T[];
  @Prop({ type: String, default: "" }) title!: string;
  @Prop({ default: null }) itemValue!: string | null;

  pickValue(item: any): string | null {
    if (typeof item === "string") {
      return item;
    }

    if (typeof item === "number") {
      return item.toString();
    }

    if (typeof item === "object" && item !== null) {
      if (
        this.itemValue &&
        Object.prototype.hasOwnProperty.call(item, this.itemValue)
      ) {
        return item[this.itemValue].toString();
      }
    }

    return null;
  }

  get cleanList(): string[] {
    if (!Array.isArray(this.items)) {
      return [];
    }

    return this.items
      .map((item) => this.pickValue(item))
      .filter((item) => item !== null) as string[];
  }
}
