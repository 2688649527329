
































import { Vue, Component, Prop } from "vue-property-decorator";
import Link from "@/components/common/Link.vue";
import { LinkType } from "@/enums";

interface ContactListItem {
  icon: string;
  href: string;
  prefix: LinkType;
}

@Component({
  components: {
    Link,
  },
})
export default class ContactList extends Vue {
  @Prop({ default: null, type: String }) phoneMobile!: string;
  @Prop({ default: null, type: String }) phoneFixed!: string;
  @Prop({ default: null, type: String }) email!: string;
  @Prop({ default: null, type: String }) url!: string;

  get validContactItems(): ContactListItem[] {
    const items = [];

    if (this.email) {
      items.push({
        icon: "mdi-email",
        prefix: LinkType.MAIL,
        href: this.email,
      });
    }

    if (this.url) {
      items.push({
        icon: "mdi-link-variant",
        prefix: LinkType.URL,
        href: this.url,
      });
    }

    if (this.phoneFixed) {
      items.push({
        icon: "mdi-phone",
        prefix: LinkType.TELEPHONE,
        href: this.phoneFixed,
      });
    }

    if (this.phoneMobile) {
      items.push({
        icon: "mdi-cellphone",
        prefix: LinkType.TELEPHONE,
        href: this.phoneMobile,
      });
    }

    return items;
  }

  get isEmpty(): boolean {
    return this.validContactItems.length === 0;
  }
}
