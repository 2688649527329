











import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class SectionHeading extends Vue {
  @Prop({ required: true, type: String }) title!: string;
  @Prop({ default: "h1", type: String }) tag!: string;
}
